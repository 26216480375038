import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "../utils/helpers";
import { getUser } from "../utils/api";
import { IUser } from "../interfaces";
import DrawerContent from "./Drawer";
import { GRAY_4, GRAY_6, LOGO_TEXT } from "../styles";

export const MainContext = React.createContext<any>(null);

const Main = () => {
  let theme = createTheme({
    palette: {
      primary: {
        main: LOGO_TEXT,
      },
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      allVariants: {
        color: GRAY_4,
      },
    },
  });
  theme = responsiveFontSizes(theme);
  const [user, setUser] = useState<IUser | null>(null);
  const [drawerOpenState, setDrawerOpenState] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const refreshUser = async () => {
    const userResponse = await getUser();
    setUser(userResponse.user);
    setLoading(false);
  };

  useEffect(() => {
    refreshUser();
  }, []);

  const toggleDrawer = () => {
    setDrawerOpenState(!drawerOpenState);
  };

  return (
    <ThemeProvider theme={theme}>
      <MainContext.Provider value={{ user: user, refreshUser: refreshUser }}>
        <ScrollToTop />
        <SwipeableDrawer
          anchor="left"
          open={drawerOpenState}
          onClose={() => setDrawerOpenState(false)}
          onOpen={() => setDrawerOpenState(true)}
        >
          <DrawerContent toggleDrawer={toggleDrawer} />
        </SwipeableDrawer>
        <Header onMenuClick={toggleDrawer} />
        <Styled>
          {loading ? (
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                width="100%"
                sx={{ fontSize: "3rem", maxWidth: "500px" }}
              />
              <Skeleton
                variant="text"
                width="100%"
                sx={{ fontSize: "2rem", maxWidth: "500px" }}
              />
              <div className="two-rem-gap" />
              <Skeleton
                variant="rectangular"
                width="100%"
                height="435px"
                sx={{ maxWidth: "500px" }}
              />
              <Skeleton
                variant="text"
                width="100%"
                sx={{ fontSize: "2rem", maxWidth: "500px" }}
              />
            </Stack>
          ) : (
            <Outlet />
          )}
          <Footer />
        </Styled>
      </MainContext.Provider>
    </ThemeProvider>
  );
};

const Styled = styled.div`
  padding: 8rem 1rem 4rem 1rem;
  background-color: ${GRAY_6};

  .two-rem-gap {
    height: 1.7rem;
  }

  .one-rem-gap {
    height: 0.6rem;
  }
`;

export default Main;

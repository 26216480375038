import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

import ProductContainer from "./ProductContainer";

const Home = () => {
  return (
    <Styled>
      <div className="introduction">
        <Typography variant="h1" gutterBottom className="main-title">
          Smart,
          <br /> Reliable
          <br /> Integrations.
        </Typography>
        <Typography variant="h3" gutterBottom className="title">
          Our products
        </Typography>
        <div className="products-container">
          <ProductContainer
            productPageUrl="/asana-in-the-inbox"
            logo1Url="https://d2sztxeooqpvky.cloudfront.net/asana.png"
            logo2Url="https://d2sztxeooqpvky.cloudfront.net/Intercom-logo-sq-black-trans.png"
            productTitle="Asana in the Inbox"
            productDescription="An integration between Intercom and Asana. Create Asana tasks
                  and receive updates on them without leaving Intercom."
          />
          <ProductContainer
            productPageUrl="/monday-in-the-inbox"
            logo1Url="https://d2sztxeooqpvky.cloudfront.net/monday_logo.png"
            logo2Url="https://d2sztxeooqpvky.cloudfront.net/Intercom-logo-sq-black-trans.png"
            productTitle="monday.com in the Inbox"
            productDescription="An integration between Intercom and monday.com. Create monday.com
                  tasks and receive updates without leaving Intercom."
          />
          <ProductContainer
            productPageUrl="/profanity-filter"
            logo1Url="https://d2sztxeooqpvky.cloudfront.net/profanity_filter_logo_512x512.png"
            productTitle="Profanity Filter"
            productDescription="An Intercom app which filters incoming messages for offensive
                  words and censors them, protecting your agents."
          />
          <ProductContainer
            productPageUrl="/explicit-image-blocker"
            logo1Url="https://d2sztxeooqpvky.cloudfront.net/explicit-image-blocker-logo.png"
            productTitle="Explicit Image Blocker"
            productDescription="An Intercom app which blocks incoming inappropriate and
                  offensive images, protecting your agents."
          />
        </div>
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  .introduction {
    padding: 1rem 0 0 0;
    text-align: center;
    width: 100%;
  }

  .main-title {
    font-weight: bold;
    text-align: center;
  }

  .title {
    margin-top: 12rem;
    margin-bottom: 4rem;
    text-align: center;
  }

  .products-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;
    flex-wrap: wrap;
    row-gap: 2rem;
    column-gap: 2rem;
  }
`;

export default Home;

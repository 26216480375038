import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import SellIcon from "@mui/icons-material/Sell";
import ArticleIcon from "@mui/icons-material/Article";
import InfoIcon from "@mui/icons-material/Info";
import HandshakeIcon from "@mui/icons-material/Handshake";
import ShieldIcon from "@mui/icons-material/Shield";

type Props = {
  toggleDrawer: () => void;
};
const DrawerContent = ({ toggleDrawer }: Props) => {
  return (
    <Box sx={{ width: 200 }} role="presentation" onClick={toggleDrawer}>
      <List>
        <ListItem disablePadding>
          <Link
            to={"/"}
            style={{ textDecoration: "none", color: "inherit", width: "100%" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItemButton>
          </Link>
        </ListItem>

        <ListItem disablePadding>
          <Link
            to={"/pricing"}
            style={{ textDecoration: "none", color: "inherit", width: "100%" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <SellIcon />
              </ListItemIcon>
              <ListItemText primary="Pricing" />
            </ListItemButton>
          </Link>
        </ListItem>

        <ListItem disablePadding>
          <Link
            to={"/asana-in-the-inbox"}
            style={{ textDecoration: "none", color: "inherit", width: "100%" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Asana in the Inbox" />
            </ListItemButton>
          </Link>
        </ListItem>

        <ListItem disablePadding>
          <Link
            to={"/monday-in-the-inbox"}
            style={{ textDecoration: "none", color: "inherit", width: "100%" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="monday.com in the Inbox" />
            </ListItemButton>
          </Link>
        </ListItem>

        <ListItem disablePadding>
          <Link
            to={"/profanity-filter"}
            style={{ textDecoration: "none", color: "inherit", width: "100%" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Profanity Filter" />
            </ListItemButton>
          </Link>
        </ListItem>

        <ListItem disablePadding>
          <Link
            to={"/explicit-image-blocker"}
            style={{ textDecoration: "none", color: "inherit", width: "100%" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Explicit Image Blocker" />
            </ListItemButton>
          </Link>
        </ListItem>

        <ListItem disablePadding>
          <Link
            to={"/about"}
            style={{ textDecoration: "none", color: "inherit", width: "100%" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary="About" />
            </ListItemButton>
          </Link>
        </ListItem>

        <ListItem disablePadding>
          <Link
            to={"/terms-of-service"}
            style={{ textDecoration: "none", color: "inherit", width: "100%" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <HandshakeIcon />
              </ListItemIcon>
              <ListItemText primary="Terms of Service" />
            </ListItemButton>
          </Link>
        </ListItem>

        <ListItem disablePadding>
          <Link
            to={"/privacy-policy"}
            style={{ textDecoration: "none", color: "inherit", width: "100%" }}
          >
            <ListItemButton>
              <ListItemIcon>
                <ShieldIcon />
              </ListItemIcon>
              <ListItemText primary="Privacy Policy" />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </Box>
  );
};

export default DrawerContent;

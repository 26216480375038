import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";

import ScrollToTop from "../utils/helpers";
import { GRAY_4, GRAY_6, LOGO_TEXT } from "../styles";

export const MainContext = React.createContext<any>(null);

const Frame = () => {
  let theme = createTheme({
    palette: {
      primary: {
        main: LOGO_TEXT,
      },
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      allVariants: {
        color: GRAY_4,
      },
    },
  });
  theme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      <Styled>
        <Outlet />
      </Styled>
    </ThemeProvider>
  );
};

const Styled = styled.div`
  padding: 2rem 1rem 4rem 1rem;
  background-color: ${GRAY_6};

  .two-rem-gap {
    height: 1.7rem;
  }

  .one-rem-gap {
    height: 0.6rem;
  }
`;

export default Frame;

import React from "react";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";

import Product from "./Product";

const ProfanityFilter = () => {
  const productName = "Explicit Image Blocker";
  const productLogoUrl =
    "https://d2sztxeooqpvky.cloudfront.net/explicit-image-blocker-logo.png";

  const Content = () => {
    return (
      <Styled>
        <Typography variant="h3" gutterBottom id="top-of-page">
          Contents
        </Typography>
        <ul>
          <Typography variant="body1">
            <li>
              <a href="#installation">Installation</a>
            </li>
            <li>
              <a href="#usage">Usage</a>
            </li>
            <li>
              <a href="#pricing">Pricing</a>
            </li>
            <li id="installation">
              <a href="#faq">FAQ</a>
            </li>
          </Typography>
        </ul>
        <Typography variant="h3" gutterBottom>
          Installation
        </Typography>
        <Typography variant="body1" gutterBottom>
          1. Visit the{" "}
          <a
            href="https://app.intercom.com/a/apps/_/appstore?app_package_code=explicit-image-blocker"
            target="_blank"
            rel="noopener noreferrer"
          >
            Intercom app store
          </a>{" "}
          and install the Explicit Image Blocker app
        </Typography>
        <Typography variant="body1" gutterBottom>
          2. You will be asked to authorize the app’s access to your Intercom
          account. We only ask for permissions strictly needed for app
          functionality.
        </Typography>
        <Typography variant="body1" gutterBottom>
          3. You’re done 🎉
        </Typography>
        <Typography variant="body1" gutterBottom>
          If you have any issues with the above, please contact us at:{" "}
          <a href="mailto:support@thackd.com">support@thackd.com</a>
        </Typography>
        <Typography variant="body1" gutterBottom id="usage">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h3" gutterBottom>
          Usage
        </Typography>
        <Typography variant="body1">
          Once installed Explicit Image Blocker will automatically start
          scanning your incoming images for offensive and inappropriate content.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If the app detects it, it will delete the offending image and send a
          new message from the bot informing the user the image has been blocked
        </Typography>
        <Typography variant="body1" gutterBottom>
          What your customers see:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/explicit-image-blocker/what-customers-see.webp"
          alt="What customers see"
          className="image"
        />
        <Typography variant="body1" gutterBottom>
          What your agents see:
        </Typography>
        <img
          src="https://d2sztxeooqpvky.cloudfront.net/explicit-image-blocker/what-agents-see.webp"
          alt="What agents see"
          className="image"
        />
        <Typography variant="body1" gutterBottom id="pricing">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h3" gutterBottom>
          Pricing
        </Typography>
        <Typography variant="body1" gutterBottom>
          The Explicit Image Blocker app is currently free to use. We may
          introduce pricing at a future date. We will provide due notice and a
          healthy discount to existing customers.
        </Typography>
        <Typography variant="body1" gutterBottom>
          We may not be able to support customers with a high volume of images
          (over c.1000 per week) on the free tier.
        </Typography>
        <Typography variant="body1" gutterBottom id="faq">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h3" gutterBottom>
          FAQ
        </Typography>
        <Typography variant="h4" gutterBottom>
          How does Explicit Image Blocker work?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Each time one of your customers sends you a message via Intercom,
          Intercom notifies us and our servers check the content of the message
          for an image.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If we find one, we pass it through a machine learning model that has
          been trained on millions of images to identify inappropriate ones in
          the following categories:
        </Typography>
        <ul>
          <Typography variant="body1">
            <li>Explicit nudity</li>
            <li>Violence</li>
            <li>Visually disturbing</li>
            <li>Rude gestures</li>
            <li>Hate symbols</li>
          </Typography>
        </ul>
        <Typography variant="body1" gutterBottom>
          This model is highly accurate and is used by some of the world’s most
          advanced companies, including Amazon. However, mistakes will occur.
          All blocked images are backed up for review by your agents.
        </Typography>
        <Typography variant="body1" gutterBottom>
          If the image is not flagged as inappropriate, we do nothing.
        </Typography>
        <Typography variant="body1">
          If the image is flagged, we save the original content, notify Intercom
          to delete the offending image and send a new bot message to inform the
          user and agent an inappropriate image was deleted.
        </Typography>
        <Typography variant="body1" gutterBottom>
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          Do you have to display “This message was deleted”?
        </Typography>
        <Typography variant="body1" gutterBottom>
          Unfortunately we are limited by Intercom. Intercom does not allow a
          message content to be changed so we have to delete it and create a new
          one with the offensive words censored. We then notify the customer
          what has happened to avoid confusion.
        </Typography>
        <Typography variant="body1" gutterBottom id="faq">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          You did not censor an image I want censored
        </Typography>
        <Typography variant="body1" gutterBottom>
          Send a copy of the image or screenshot to:{" "}
          <a href="mailto:support@thackd.com">support@thackd.com</a>
        </Typography>
        <Typography variant="body1" gutterBottom id="faq">
          <a href="#top-of-page">Back to top</a>
        </Typography>

        <Typography variant="h4" gutterBottom>
          I want to change how the app works or request a new feature
        </Typography>
        <Typography variant="body1" gutterBottom>
          Contact us at <a href="mailto:hello@thackd.com">hello@thackd.com</a>.
          We love to hear feedback and requests.
        </Typography>
        <Typography variant="body1" gutterBottom id="faq">
          <a href="#top-of-page">Back to top</a>
        </Typography>
      </Styled>
    );
  };

  return (
    <Product
      productName={productName}
      productLogoUrl={productLogoUrl}
      Content={Content}
    />
  );
};

const Styled = styled.div`
  max-width: 800px;

  ul ul {
    padding-left: 20px;
  }

  ul p {
    margin-bottom: 0;
  }

  p {
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 2rem;
  }

  h3 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  h4 {
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  .border-left {
    border-left: 1px solid #000;
    padding-left: 1rem;
  }

  .image {
    max-width: 600px;
    @media screen and (max-width: 600px) {
      max-width: 100%;
    }
    margin-bottom: 2rem;
  }
`;

export default ProfanityFilter;

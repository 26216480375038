import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

type ProductProps = {
  productName: string;
  productLogoUrl: string;
  Content: React.FC;
};
const Product = ({ productName, productLogoUrl, Content }: ProductProps) => {
  return (
    <Styled>
      <div className="introduction">
        <div className="picture-container">
          <img
            className="product-image"
            src={productLogoUrl}
            alt={productName}
          />
        </div>
        <Typography variant="h1" gutterBottom>
          {productName}
        </Typography>

        <Content />
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  .introduction {
    padding: 0 0 0 0;
    text-align: left;
  }

  .picture-container {
    margin-bottom: 2rem;

    .product-image {
      max-width: 150px;
      border-radius: 50%;
    }
  }

  .paragraph-gap {
    margin-top: 2rem;
  }

  .display-linebreak {
    white-space: pre-line;
  }
`;

export default Product;

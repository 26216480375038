import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

const PrivacyPolicy = () => {
  return (
    <Styled>
      <div className="content">
        <Typography variant="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="body1" gutterBottom>
          <p>
            When you use our services, you’re trusting us with your information.
            We understand this is a big responsibility and work hard to protect
            your information and give you the opportunity to control it. This
            Privacy Policy is meant to help you understand what information we
            collect, why we collect it, and how you can manage, and delete your
            information.
          </p>

          <p>
            If you have any questions about this Privacy Policy, you can contact
            us.
          </p>

          <h2>Information we collect</h2>
          <p>
            We collect information to provide better services to our customers.
            The types of personal information or personal data we may collect
            about you include:
          </p>
          <ul>
            <li>your name;</li>
            <li>
              your contact details, including email address, mailing address
              and/or telephone number;
            </li>
            <li>your demographic information, such as postcode;</li>
            <li>your preferences and/or opinions;</li>
            <li>
              details of the services we have provided to you and/or that you
              have enquired about, and our response to you;
            </li>
            <li>
              additional personal information that you provide to us, directly
              or indirectly, through your use of our services, associated
              applications, associated social media platforms and/or accounts
              from which you permit us to collect information; and
            </li>
            <li>
              any other personal information requested by us and/or provided by
              you or a third party.
            </li>
          </ul>
          <p>
            We may collect these types of personal information directly from you
            or from third parties, e.g. when you contact us through our support
            channels or if you visit our website or documentation.
          </p>

          <h2>Why we collect data</h2>

          <h3>Maintain & improve our services</h3>
          <p>
            We use the information we collect to maintain and improve our
            services. For example, we use your information to troubleshoot
            issues that you report to us. And we use your information to make
            improvements to our services — for example, understanding common
            usability issues with specific features helps us improve our
            services where it's most useful to you.
          </p>

          <h3>Develop new services</h3>
          <p>
            We use the information we collect in existing services to help us
            develop new ones.
          </p>

          <h3>Measure performance</h3>
          <p>
            We use data for analytics and measurement to understand how our
            services are used.
          </p>

          <h3>Communicate with you</h3>
          <p>
            We use information we collect, like your email address, to interact
            with you directly. For example, we may let you know about important
            upcoming changes or improvements to our services. And if you contact
            us, we’ll keep a record of your request in order to help solve any
            issues you might be facing.
          </p>

          <h2>Your privacy controls</h2>

          <h3>Choice and consent</h3>
          <p>
            When you provide personal information to us, we assume that you
            consent to our collecting it and using it for that specific reason
            only. If we ask for your personal information for a secondary
            reason, like marketing, we will either ask you directly for your
            expressed consent, or provide you with an opportunity to say no.
          </p>

          <h3>Information from third parties</h3>
          <p>
            If we receive personal information about you from a third party, we
            will protect it as set out in this Privacy Policy. If you are a
            third party providing personal information about somebody else, you
            represent and warrant that you have such person’s consent to provide
            the personal information to us.
          </p>

          <h3>Restrict</h3>
          <p>
            You may choose to restrict the collection or use of your personal
            information. If you have previously agreed to us using your personal
            information for direct marketing purposes, you may change your mind
            at any time by contacting us using the details at the beginning of
            this policy. If you ask us to restrict how we process your personal
            information, we will let you know how the restriction affects your
            use of our services.
          </p>

          <h3>Access and data portability</h3>
          <p>
            You may request details of the personal information that we hold
            about you. You may request a copy of the personal information we
            hold about you. Where possible, we will provide this information in
            CSV format or other easily readable machine format. You may request
            that we erase the personal information we hold about you at any
            time. You may also request that we transfer this personal
            information to another third party (data portability).
          </p>

          <h3>Correction</h3>
          <p>
            If you believe that any information we hold about you is inaccurate,
            out of date, incomplete, irrelevant or misleading, please contact us
            using the details below. We will take reasonable steps to correct
            any information found to be inaccurate, incomplete, misleading or
            out of date.
          </p>

          <h3>Complaints</h3>
          <p>
            If you believe that we have breached the Privacy Principles or an
            article of the GDPR and wish to make a complaint, please contact us
            using the details at the beginning of this policy and provide us
            with full details of the alleged breach. We will promptly
            investigate your complaint and respond to you, in writing, setting
            out the outcome of our investigation and the steps we will take to
            deal with your complaint.
          </p>

          <h3>Unsubscribe</h3>
          <p>
            To unsubscribe from our e-mail database or opt-out of communications
            (including marketing communications), please contact us using the
            details at the top of this policy or opt-out using the opt-out
            facilities provided in the communication.
          </p>

          <h2>Sharing your information</h2>

          <p>We may disclose personal information to:</p>
          <ul>
            <li>
              third party service providers for the purpose of enabling them to
              provide their services, including (without limitation)
            </li>
            <li>
              IT service providers, data storage, web-hosting providers,
              professional advisors and payment systems operators;
            </li>
            <li>our employees, contractors and/or related entities;</li>
            <li>our existing or potential agents or business partners;</li>
            <li>payment systems operators;</li>
            <li>
              anyone to whom our business or assets (or any part of them) are,
              or may (in good faith) be, transferred;
            </li>
            <li>
              third parties to collect and process data, such as Google
              Analytics or other relevant businesses.
            </li>
          </ul>

          <p>
            Where we disclose your personal information to third parties,
            including data processors or data sub-processors, we will request
            that the third party handle your personal information in accordance
            with this Privacy Policy. The third-party will only process your
            personal information in accordance with written instructions from us
            and we require that the third party either complies with the privacy
            shield principles set out in the GDPR or another mechanism set out
            by applicable EU & Swiss data protection laws for the transfer and
            processing of personal information. When we refer to ‘processing’ in
            this clause and this Privacy Policy in general, we mean any
            operation or set of operations which is performed on personal
            information, whether or not by automated means, such as collecting,
            recording, organizing, structuring, storage, adaptation or
            alteration, retrieval, consultation, use, disclosure by
            transmission, dissemination or otherwise making available personal
            information.
          </p>
          <p>
            The third-parties that we use to process your personal information
            may include:
          </p>
          <ul>
            <li>Intercom R&D Unlimited Company</li>
            <li>Asana, Inc</li>
            <li>monday.com</li>
            <li>Salesforce.com, Inc (Heroku - data hosting)</li>
            <li>Functional Software, Inc. (Sentry - error monitoring)</li>
            <li>Amazon Web Services (data hosting)</li>
          </ul>

          <p>
            By providing us with personal information, you consent to the
            disclosure of your personal information to third parties and, if you
            are a European Union (EU) citizen, to third parties that reside
            outside the EU.
          </p>

          <h2>Keeping your information secure</h2>

          <p>
            We are committed to ensuring that the personal information we
            collect is secure. In order to prevent unauthorized access or
            disclosure, all our services are built with industry standard
            security features that protect your information. We have also put in
            place suitable physical, electronic and managerial procedures to
            safeguard and secure personal information and protect it from
            misuse, interference, loss and unauthorized access, modification and
            disclosure. And if we do detect something risky that we think you
            should know about, we’ll notify you and help guide you through steps
            to stay better protected.
          </p>
          <p>
            We cannot guarantee the security of any information that is
            transmitted to or by us over the Internet. The transmission and
            exchange of information is carried out at your own risk. Although we
            take measures to safeguard against unauthorized disclosures of
            information, we cannot assure you that the personal information we
            collect will not be disclosed in a manner that is inconsistent with
            this Privacy Policy.
          </p>

          <h2>Compliance</h2>

          <p>
            We regularly review this Privacy Policy and make sure that we
            process your information in ways that comply with it. Data transfers
          </p>
          <p>
            We maintain servers in the EU and your information may be processed
            on servers located outside of the country where you are located.
            Data protection laws vary among countries, with some providing more
            protection than others. Regardless of where your information is
            processed, we apply the same protections described in this policy.
          </p>

          <h2>About this policy</h2>

          <h3>When this policy applies</h3>
          <p>
            This Privacy Policy applies to all of the services offered by
            Thackd, including services offered on third-party sites, such as
            Asana or Intercom. This Privacy Policy doesn’t apply to services
            that have separate privacy policies that do not incorporate this
            Privacy Policy.
          </p>
          <h3>Changes to this policy</h3>
          <p>
            We may, at any time and at our discretion, vary this Privacy Policy.
            We always indicate the date the last changes were published. We will
            notify you if we amend this Privacy Policy, by contacting you
            through the contact details you have provided to us. Any amended
            Privacy Policy is effective once we notify you of the change.
          </p>
        </Typography>
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  padding: 0 0 0 0;
  text-align: left;
  max-width: 800px;

  p {
    margin-bottom: 1.5rem;
  }
`;

export default PrivacyPolicy;

import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

const TermsOfService = () => {
  return (
    <Styled>
      <div className="content">
        <Typography variant="h1" gutterBottom>
          End User License Agreement
        </Typography>
        <Typography variant="body1" gutterBottom>
          <p>
            This End User License Agreement (EULA) is a binding legal agreement
            between Thackd (the “Licensor”), a provider of cloud-based and
            downloadable applications including related documentation and
            services (the “Software”) through the Intercom App Store or any
            other means that interoperate with applicable products and services
            and you (either an individual or a single legal entity, the
            “Licensee”) whose details are provided to the Licensor when you
            order or receive the Software from the Licensor or a Reseller (the
            “Purchase”).
          </p>

          <p>
            The Licensee agrees to be bound by the terms of this EULA by
            copying, downloading, installing, licensing, purchasing or otherwise
            using the Software. If the Licensee does not agree to the terms of
            this EULA, the Licensee may not copy, download, install or otherwise
            use the Software.
          </p>
          <h2>1 Grant of License</h2>
          <p>
            1.1 The Software is licensed, not sold, and no ownership right is
            conveyed to the Licensee, irrespective of the use of terms in this
            EULA.
          </p>
          <p>
            1.2 This EULA grants the Licensee the rights according to the
            Licensee’s order through the Intercom App Store or a Reseller and
            the respective specifications including, but not limited to, for
            example, the license, a maintenance or subscription period, the
            price, support and other services, and the number of authorized
            users (the “Order”)
          </p>
          <p>
            1.3 The Licensor grants the Licensee a non-exclusive,
            non-sublicensable, non-transferable and worldwide license to use the
            Software limited to the scope of the Order. The Licensor reserves
            all rights not expressly granted to the Licensee in this EULA.
          </p>

          <p>
            1.4 The rights under this EULA are non-exclusive, non-sublicensable
            and non-transferable. The Licensee may not convey, sell, or transfer
            the Software to any third party without the Licensor’s prior express
            written consent.
          </p>
          <p>
            1.5 The Licensee may not decompile, disassemble, modify or reverse
            engineer the Software in whole or in part, or create any derivative
            works from the Software without the Licensor’s prior express written
            consent.
          </p>
          <p>
            1.6 The Licensee is allowed to make necessary backup copies for the
            purpose of backup only and for no other purpose.
          </p>
          <p>
            1.7 The Licensee is allowed to install the Software and make the
            Software available for use on systems controlled, leased or owned by
            the Licensee or a third-party provider as long as the Licensee
            remains fully responsible for any compliance with this EULA.
          </p>
          <p>
            1.8 The Software will, if not otherwise agreed or offered, delivered
            only in electronic form.
          </p>

          <h2>2 Third-Party Software</h2>
          <p>
            2.1 The Licensee acknowledges that the Software may contain software
            licensed by the Licensor from third parties, including open-source
            software, and embedded in the Software and that in addition to this
            EULA, additional obligations may apply in relation to any use of
            such third-party software by the Licensee. The Licensor shall not be
            liable for any third-party software.
          </p>
          <p>
            2.2 The Software may depend on, require and use various third-party
            Application Programming Interface (APIs). The Licensor shall not be
            liable for any third-party APIs.
          </p>

          <h2>3 Changes to the Software</h2>
          <p>
            3.1 The Licensee acknowledges that in order to provide improved
            customer experience, the Licensor may make changes to the Software,
            and may update the applicable documentation accordingly. Subject to
            the Licensor’s obligation to provide the Software under existing
            agreements or orders to provide the Software, the Licensor may
            discontinue the provision of the Software (in part or in full), or
            any portion, feature, or element of the Software for any reason at
            any time without liability to the Licensee.
          </p>

          <h2>4 Payment Terms, Modifications and Termination</h2>
          <p>
            4.1 The Licensor may ask for upfront payments as a condition to
            grant any license to the Licensee. Any license shall only be granted
            to the Licensee if the Licensor has received full payment for the
            respective license.
          </p>
          <p>
            4.2 The Licensor may modify this EULA at any time and at its sole
            discretion. If a modification is detrimental to the Licensee, the
            Licensor shall use reasonable efforts to notify any affected
            Licensee by sending e-mails to known e-mail addresses or by
            publishing a notification on the Licensor’s website, on the
            Atlassian Marketplace or on the Intercom App Store. Continued use of
            the Software after such notification by the Licensee is considered
            as an agreement with the modified EULA.
          </p>
          <p>
            4.3 The Licensor may terminate any license if the Licensor is in
            default with payments or if the Licensor cannot reasonably expect
            the Licensee to make due payments.
          </p>
          <p>
            4.4 The license shall terminate automatically if the Licensor fails
            to comply with this EULA.
          </p>

          <h2>5 Limitation of Liability and Warranty</h2>
          <p>
            5.1 The Licensor will use reasonable efforts to provide solutions
            for any reported issues. The Licensor will provide maintenance and
            support services, unless such services cannot be reasonably
            expected, for the Software on the Licensee’s request as set out in
            your Order and for the support period as indicated in your Order
            (“Support Period”).
          </p>
          <p>
            5.2 The Software is provided on an “as is” and “as available” basis.
            To the fullest extent permitted by applicable laws, all warranties,
            representations, conditions, and all other terms of any kind
            whatsoever express or implied by statute or common law, including,
            but not limited to, any warranties of fitness for a specific
            purpose, are excluded from this EULA.
          </p>
          <p>
            5.3 The Licensee assumes sole responsibility for results obtained
            from the use of the Software, and for conclusions drawn from such
            use. The Licensor shall have no liability for any damage caused by
            errors or omissions in any information or instructions provided to
            the Licensor by the Licensee in connection with the Software, or any
            actions taken by the Licensor at the Licensee’s direction.
          </p>
          <p>
            5.4 The Licensor shall not be liable whether in tort, contract,
            misrepresentation, restitution or otherwise for any loss of profits,
            loss of business, depletion of goodwill and/or similar losses or
            loss or corruption of data or information, or pure economic loss, or
            for any special, direct, indirect, or consequential loss, costs,
            damages, charges, or expenses however arising under this EULA or the
            Licensee’s use of the Software.
          </p>
          <p>
            5.5 In the event exclusion of liability of the Licensor in
            accordance with Clause 5.4 is prohibited under applicable laws, the
            Licensor’s total aggregate liability arising in connection with this
            EULA or the Licensee’s use of the Software shall be limited to the
            amount paid or payable to the Licensor in the preceding 12 months.
          </p>

          <h2>6 Indemnification</h2>
          <p>
            6.1 If the Software becomes, or in the opinion of the Licensor may
            become, the subject of a claim of infringement of any third party
            right, the Licensor may, at its option and in its discretion: (i)
            procure for Licensee the right to use the Software free of any
            liability; (ii) replace or modify the Software to make it
            non-infringing, or (iii) allow for liability claims not exceeding
            the amount paid or payable to the Licensor in the 12 months
            preceding the claim.
          </p>
          <p>
            6.2 Licensee agrees to indemnify and hold the Licensor, and its
            subsidiaries, affiliates, officers, agents, and employees, harmless
            from any claims by third parties, and any related damages, losses,
            or costs (including, without limitation, reasonable attorney fees
            and costs), arising out of Licensee’s use of the Software, or
            Licensee’s violation of the EULA or any rights of a third party.
          </p>

          <h2>7 Miscellaneous</h2>
          <p>
            7.1 The Licensee grants the Licensor the right to use the Licensee’s
            company name/name and logo and any review provided or published in
            relation to the Software within promotional material, on the
            Licensor’s social media channels and on the Licensor’s website. The
            Licensee can revoke this right for future usage at any time by
            submitting a respective request to the Licensor.
          </p>
          <p>
            7.2 If any provision of this EULA should be held to be unenforceable
            for any reason, such provision shall be amended only to the extent
            necessary to make it enforceable and the remaining provisions of
            this EULA shall remain in full force and effect.
          </p>
          <p>
            7.3 This EULA constitutes the entire agreement between the parties
            and supersedes and extinguishes all previous agreements, promises,
            assurances, warranties, representations, and understandings between
            them, whether written or oral, relating to its subject matter. Each
            party acknowledges that in entering into this agreement it does not
            rely on, and shall have no remedies in respect of, any statement,
            representation, assurance, or warranty that is not set out in this
            agreement.
          </p>
          <p>
            7.4 The Licensor shall have no liability to the Licensee under this
            EULA if it is prevented from or delayed in performing its
            obligations under this EULA, or from carrying on its business, by
            acts, events, omissions, or accidents beyond its reasonable control,
            including, without limitation, epidemics or pandemics, strikes,
            lock-outs or other industrial disputes (whether involving the
            workforce of the Supplier or any other party), failure of a utility
            service or transport or telecommunications network, act of God, war,
            riot, civil commotion, malicious damage, compliance with any law or
            governmental order, rule, regulation or direction, accident,
            breakdown of plant or machinery, fire, flood, storm or default of
            suppliers or sub-contractors, provided that the Licensee is notified
            of such an event and its expected duration.
          </p>
        </Typography>
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  padding: 0 0 0 0;
  text-align: left;
  max-width: 800px;

  p {
    margin-bottom: 1.5rem;
  }
`;

export default TermsOfService;

import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import { Link } from "react-router-dom";

import { LOGO_YELLOW } from "../styles";

type Props = {
  productPageUrl: string;
  logo1Url: string;
  logo2Url?: string;
  productTitle: string;
  productDescription: string;
};

const ProductContainer = ({
  productPageUrl,
  logo1Url,
  logo2Url,
  productTitle,
  productDescription,
}: Props) => {
  return (
    <StyledCard>
      <CardActionArea
        component={Link}
        to={productPageUrl}
        className="card-action-area"
      >
        <CardContent className="card-content">
          <span>
            <img
              className="product-logo right-margin"
              src={logo1Url}
              alt="Product Logo"
            />
            {logo2Url && (
              <img className="product-logo" src={logo2Url} alt="Product Logo" />
            )}
          </span>
          <Typography variant="h4" gutterBottom className="product-title">
            {productTitle}
          </Typography>
          <Typography variant="body1">{productDescription}</Typography>
        </CardContent>
      </CardActionArea>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: 100%;
  @media screen and (min-width: 600px) {
    width: 30%;
  }
  min-height: 200px;
  background-color: ${LOGO_YELLOW};
  border-radius: 8px;
  padding: 1rem;

  .card-action-area {
    height: 100%;

    .card-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .product-logo {
        max-width: 100px;
        border-radius: 15px;
        margin-bottom: 1.5rem;
      }

      .product-title {
        font-weight: bold;
      }

      p {
        text-align: left;
      }
    }
  }

  .right-margin {
    margin-right: 1rem;
  }
`;

export default ProductContainer;

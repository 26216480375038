const SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN || "";

function getToken() {
  return localStorage.getItem("thackd_jwt");
}

export function removeToken() {
  localStorage.removeItem("thackd_jwt");
}

function fetchData(url: string, headers: any | null) {
  if (!headers) {
    headers = {};
  }

  return fetch(url, { headers: headers }).then((response) => response.json());
}

function postData(url: string, data: any, headers: any) {
  if (!headers) {
    headers = {
      "Content-Type": "application/json",
    };
  } else {
    headers = {
      ...headers,
      "Content-Type": "application/json",
    };
  }

  return fetch(url, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
  });
}

export function login(email: string, password: string) {
  email = email.toLowerCase();
  return postData(SERVER_DOMAIN + "/api/token/", { email, password }, null);
}

export function signUp(email: string, password: string, profileId: string) {
  return postData(
    SERVER_DOMAIN + "/api/create-user/",
    { email, password, pid: profileId },
    null,
  );
}

export function getUser() {
  const token = getToken();
  if (token) {
    return fetchData(SERVER_DOMAIN + "/api/get-user/", {
      Authorization: `Bearer ${token}`,
    });
  } else {
    return false;
  }
}

export async function checkUserExists(email: string) {
  const response = await postData(
    SERVER_DOMAIN + "/api/check-user-exists/",
    { email },
    null,
  );
  const data = await response.json();
  return { email: data.email, isLegacy: data.isLegacy };
}

export function postCheckout(itemKey: string, product: "ASANA" | "MONDAY") {
  const token = getToken();
  return postData(
    SERVER_DOMAIN + "/api/create-checkout-session/",
    {
      itemKey,
      product,
    },
    { Authorization: `Bearer ${token}` },
  );
}

export function postUpgradeSubscription() {
  const token = getToken();
  return postData(
    SERVER_DOMAIN + "/api/upgrade-subscription/",
    {},
    { Authorization: `Bearer ${token}` },
  );
}

export function getManageSubscriptionURL() {
  const token = getToken();
  return fetchData(SERVER_DOMAIN + "/api/create-portal-session/", {
    Authorization: `Bearer ${token}`,
  });
}

export function orderSuccess(sessionId: string, productId?: string) {
  const token = getToken();
  return postData(
    SERVER_DOMAIN + "/api/order-success/",
    {
      sessionId,
      productId,
    },
    { Authorization: `Bearer ${token}` },
  );
}

export function resetPassword(email: string) {
  return postData(SERVER_DOMAIN + "/api/reset-password/", { email }, null);
}

export function getStripeClientSecret(reservationId: string) {
  const token = getToken();
  return postData(
    SERVER_DOMAIN + "/api/get-stripe-client-secret/",
    {
      reservationId,
    },
    { Authorization: `Bearer ${token}` },
  );
}

export function getIntercomWorkspace(profileId: string) {
  return fetchData(
    SERVER_DOMAIN + `/api/get-intercom-workspace?profileId=${profileId}`,
    null,
  );
}

import React, { useState } from "react";
import styled from "@emotion/styled";
import { Alert, FormControl, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { resetPassword } from "../utils/api";
import Typography from "@mui/material/Typography";

const ResetPasswordComponent = () => {
  const [email, setEmail] = useState<string>("");
  const [validatedEmail, setValidatedEmail] = useState<boolean>(true);
  const [validationEmailText, setValidationEmailText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);

  function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const onPasswordReset = async () => {
    setLoading(true);
    setValidatedEmail(true);
    setValidationEmailText("");
    let emailIsValid = true;

    if (!validateEmail(email)) {
      setValidatedEmail(false);
      emailIsValid = false;
      setValidationEmailText("Valid email required" as string);
    }

    if (emailIsValid) {
      try {
        const response = await resetPassword(email);
        if (response.status !== 200) {
          setValidatedEmail(false);
          setValidationEmailText(
            "User not found. Please check the email address.",
          );
        } else {
          setEmailSent(true);
        }
      } catch (e) {
        console.error(e);
      }
    }

    setLoading(false);
  };

  return (
    <Styled>
      <Typography variant="h5" gutterBottom>
        Reset Password
      </Typography>
      <FormControl>
        <TextField
          required
          error={!validatedEmail}
          helperText={validationEmailText}
          label="Email"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </FormControl>

      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={onPasswordReset}
      >
        Reset Password
      </LoadingButton>
      {emailSent && (
        <Alert severity="success">Email sent. Please check your inbox.</Alert>
      )}
    </Styled>
  );
};

const Styled = styled.div`
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  row-gap: 12px;
  max-width: 500px;
  margin: 50px 16px 0 16px;
`;

export default ResetPasswordComponent;

import React, { useContext, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { FormControl, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { getIntercomWorkspace, login, signUp } from "../utils/api";
import { MainContext } from "./Main";
import { Link, useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";

const SignUpComponent = () => {
  const context = useContext(MainContext);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [validatedEmail, setValidatedEmail] = useState<boolean>(true);
  const [validationEmailText, setValidationEmailText] = useState<string>("");
  const [validatedPassword, setValidatedPassword] = useState<boolean>(true);
  const [validationPasswordText, setValidationPasswordText] = useState<string>(
    "Minimum 7 characters" as string,
  );
  const [validatedConfirmPassword, setValidatedConfirmPassword] =
    useState<boolean>(true);
  const [validationConfirmPasswordText, setValidationConfirmPasswordText] =
    useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [profileId, setProfileId] = useState<string | null>(
    useParams().profileId || null,
  );
  const [workspaceName, setWorkspaceName] = useState<string>("");
  const [appName, setAppName] = useState<string>("");
  const [userAlreadyExists, setUserAlreadyExists] = useState<boolean>(false);
  const [invalidWorkspaceId, setInvalidWorkspaceId] = useState<boolean>(false);

  useEffect(() => {
    // Get the Intercom workspace name when page loads
    const getIntercomWorkspaceAsync = async (profileId: string) => {
      try {
        const workspace = await getIntercomWorkspace(profileId);
        if (!workspace.workspaceName) {
          setInvalidWorkspaceId(true);
          return;
        }
        setWorkspaceName(workspace.workspaceName);
        setAppName(workspace.thackdAppName);
        setUserAlreadyExists(workspace.userAlreadyExists);
        setInvalidWorkspaceId(false);
      } catch (e) {
        console.log(e);
      }
    };
    if (profileId) {
      getIntercomWorkspaceAsync(profileId);
    } else {
      setInvalidWorkspaceId(true);
    }
  }, [profileId]);

  function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const onSignUp = async () => {
    setLoading(true);
    setValidatedEmail(true);
    setValidationEmailText("");
    setValidatedPassword(true);
    setValidationPasswordText("Minimum 7 characters" as string);
    setValidatedConfirmPassword(true);
    setValidationConfirmPasswordText("");

    if (password.length < 7) {
      setValidatedPassword(false);
      setValidationPasswordText("Minimum 7 characters" as string);
    } else if (password !== confirmPassword) {
      setValidatedConfirmPassword(false);
      setValidationConfirmPasswordText("Passwords do not match" as string);
    }

    if (!validateEmail(email)) {
      setValidatedEmail(false);
      setValidationEmailText("Valid email required" as string);
    }

    if (validatedEmail && profileId) {
      try {
        const signUpResponse = await signUp(email, password, profileId);
        if (!signUpResponse.ok) {
          throw new Error(await signUpResponse.json());
        }
      } catch (e: any) {
        setValidatedPassword(false);
        setValidatedConfirmPassword(false);
        setValidationPasswordText(e.message);
        setLoading(false);
        return false;
      }

      try {
        const response = await login(email, password);
        const data = await response.json();
        localStorage.setItem("thackd_jwt", data.access);
        await context.refreshUser();
        navigate("/pricing");
      } catch (e) {
        console.error(e);
      }
    }

    setLoading(false);
  };

  return (
    <Styled>
      {userAlreadyExists ? (
        <Typography variant="h5" gutterBottom>
          It looks like you have already registered with a different email
          address. Please <Link to="/login">Log In</Link> or contact us for
          support.
        </Typography>
      ) : invalidWorkspaceId ? (
        <Typography variant="h5" gutterBottom>
          Invalid sign up link. Please contact us for support.
        </Typography>
      ) : (
        <div className="sign-up-form-container">
          <Typography variant="h5" gutterBottom>
            {appName} Sign up
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Intercom workspace: {workspaceName}
          </Typography>
          <FormControl>
            <TextField
              required
              error={!validatedEmail}
              helperText={validationEmailText}
              label="Email"
              type="email"
              autoComplete="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </FormControl>
          <FormControl>
            <TextField
              required
              error={!validatedPassword}
              helperText={validationPasswordText}
              label="Password"
              type="password"
              autoComplete="new-password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </FormControl>
          <FormControl>
            <TextField
              required
              error={!validatedConfirmPassword}
              helperText={validationConfirmPasswordText}
              label="Confirm Password"
              type="password"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
            />
          </FormControl>
          <LoadingButton
            loading={loading}
            variant="contained"
            onClick={onSignUp}
          >
            Sign up
          </LoadingButton>
        </div>
      )}

      <div className="center">
        <Typography variant={"body2"}>
          Have an account? Log in <Link to="/login">here</Link>
        </Typography>
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  row-gap: 12px;
  margin: 50px 16px 0 16px;

  .center {
    display: flex;
    justify-content: center;
    max-width: 500px;
  }

  .sign-up-form-container {
    display: flex;
    align-content: flex-start;
    flex-direction: column;
    row-gap: 12px;
    max-width: 500px;
  }
`;

export default SignUpComponent;

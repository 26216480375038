import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Styled>
      <Typography>
        <Link to={"/"}>Home</Link>
      </Typography>
      <Typography>
        <Link to={"/login"}>Login</Link>
      </Typography>
      <Typography paragraph>
        <Link to={"/pricing"}>Pricing</Link>
      </Typography>
      <Typography>
        <Link to={"/asana-in-the-inbox"}>Asana in the Inbox</Link>
      </Typography>
      <Typography>
        <Link to={"/monday-in-the-inbox"}>monday in the Inbox</Link>
      </Typography>
      <Typography>
        <Link to={"/profanity-filter"}>Profanity Filter</Link>
      </Typography>
      <Typography paragraph>
        <Link to={"/explicit-image-blocker"}>Explicit Image Blocker</Link>
      </Typography>
      <Typography>
        <Link to={"/about"}>About</Link>
      </Typography>
      <Typography>
        <Link to={"/terms-of-service"}>Terms of Service</Link>
      </Typography>
      <Typography paragraph>
        <Link to={"/privacy-policy"}>Privacy Policy</Link>
      </Typography>

      <Typography paragraph>
        Contact us: <a href="mailto:support@thackd.com">support@thackd.com</a>
      </Typography>
      <p className="copyright">© 2024 thackd.com </p>
    </Styled>
  );
};

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  margin: 5rem 0;

  .copyright {
    font-size: 0.8rem;
    margin-top: 3rem;
    margin-bottom: 0rem;
  }
`;

export default Footer;

import React, { useContext, useState } from "react";
import styled from "@emotion/styled";
import { Alert, FormControl, TextField } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { login } from "../utils/api";
import { Link, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";

import { MainContext } from "./Main";

const LoginComponent = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [validatedEmail, setValidatedEmail] = useState<boolean>(true);
  const [validationEmailText, setValidationEmailText] = useState<string>("");
  const [validatedPassword, setValidatedPassword] = useState<boolean>(true);
  const [validationPasswordText, setValidationPasswordText] =
    useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [invalidLogin, setInvalidLogin] = useState<boolean>(false);
  const navigate = useNavigate();
  const context = useContext(MainContext);

  function validateEmail(email: string) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const onLogin = async () => {
    setLoading(true);
    setValidatedEmail(true);
    setValidationEmailText("");
    setValidatedPassword(true);
    setValidationPasswordText("");
    setInvalidLogin(false);
    let emailIsValid = true;

    if (password.length < 1) {
      setValidatedPassword(false);
      setValidationPasswordText("Password required");
    }

    if (!validateEmail(email)) {
      setValidatedEmail(false);
      emailIsValid = false;
      setValidationEmailText("Valid email required");
    }

    if (emailIsValid) {
      try {
        const response = await login(email, password);
        if (response.status !== 200) {
          setValidatedEmail(false);
          setValidatedPassword(false);
          setInvalidLogin(true);
        } else {
          const data = await response.json();
          localStorage.setItem("thackd_jwt", data.access);
          await context.refreshUser();
          navigate("/");
        }
      } catch (e) {
        console.error(e);
      }
    }

    setLoading(false);
  };

  return (
    <Styled>
      <Typography variant="h5" gutterBottom>
        Log in
      </Typography>
      <FormControl>
        <TextField
          required
          error={!validatedEmail}
          helperText={validationEmailText}
          label="Email"
          type="email"
          autoComplete="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </FormControl>
      <FormControl>
        <TextField
          required
          error={!validatedPassword}
          helperText={validationPasswordText}
          label="Password"
          autoComplete="current-password"
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
        />
      </FormControl>
      <LoadingButton loading={loading} variant="contained" onClick={onLogin}>
        Login
      </LoadingButton>
      {invalidLogin && (
        <Alert severity="error">Invalid username or password</Alert>
      )}
      <div className="center">
        <Typography variant={"body2"}>
          New user? Sign up using the unique link you were emailed on install.
        </Typography>
        <Typography variant={"body2"}>
          <Link to="/reset-password">Forgot your password?</Link>
        </Typography>
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  display: flex;
  align-content: flex-start;
  flex-direction: column;
  row-gap: 12px;
  max-width: 500px;
  margin: 50px 16px 0 16px;

  .center {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
`;

export default LoginComponent;

import React from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";

const About = () => {
  return (
    <Styled>
      <div className="introduction">
        <Typography variant="h1" gutterBottom>
          About Us
        </Typography>
        <Typography variant="body1" gutterBottom>
          Thackd was created in early 2023 in Dublin, Ireland by a software
          engineer and product manager with prior experience in big tech and
          small startups across Europe and the US.
        </Typography>

        <Typography variant="body1" gutterBottom>
          This experience gave them a unique perspective on the challenges faced
          by businesses of all sizes when it comes to integrating and working
          with customer support software. They decided to create Thackd to help
          businesses of all sizes overcome these challenges.
        </Typography>

        <Typography variant="body1" gutterBottom>
          Please reach out to us if you have any questions, feedback or feature
          requests. We're always happy to hear from our customers.{" "}
          <a href="mailto:hello@thackd.com">hello@thackd.com</a>
        </Typography>
      </div>
    </Styled>
  );
};

const Styled = styled.div`
  .introduction {
    padding: 0 0 0 0;
    text-align: left;
    max-width: 800px;

    p {
      margin-bottom: 1.5rem;
    }
  }
`;

export default About;

import React, { useState } from "react";
import styled from "@emotion/styled";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { calculateMondayPricePerSeat } from "../utils/helpers";

const PricingMonday = () => {
  const [numSeats, setNumSeats] = useState("1");
  const [pricePerSeat, setPricePerSeat] = useState("10");

  const onSeatsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNumSeats(event.target.value);

    if (event.target.value === "" || event.target.value === "0") {
      setPricePerSeat("0");
    } else if (Number.isInteger(Number(event.target.value))) {
      setPricePerSeat(
        calculateMondayPricePerSeat(parseInt(event.target.value)).toFixed(2),
      );
    }
  };

  return (
    <Styled>
      <div className="product">
        <div className="description">
          <Typography variant="h1" gutterBottom>
            monday.com in the Inbox Pricing
          </Typography>
        </div>
        <div className="pricing-cards-container">
          <Card className="pricing-card">
            <Typography variant="body1" gutterBottom>
              Our pricing is based on the number of monday.com seats you have.
              The price per user decreases, the more seats you have.
            </Typography>
            <Typography variant="body1" gutterBottom className="top-gap">
              All plans include:
            </Typography>
            <ul>
              <Typography variant="body1" gutterBottom>
                <li>Unlimited task creation</li>
                <li>Unlimited task linking</li>
                <li>monday.com to Intercom real-time task syncing</li>
                <li>Responsive support</li>
                <li>Cancel anytime</li>
              </Typography>
            </ul>

            <Typography variant="body1" gutterBottom className="top-gap">
              Enter number of seats to calculate the price per seat:
            </Typography>
            <TextField
              id="outlined-basic"
              label="Seats"
              variant="outlined"
              value={numSeats}
              onChange={onSeatsChange}
            />
            <Typography variant="body1" gutterBottom className="price-per-seat">
              <span className="emphasize-text">
                Price per seat: ${pricePerSeat}
              </span>
            </Typography>

            <TableContainer component={Paper}>
              <Table className="table-container" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>monday.com Inbox seats</TableCell>
                    <TableCell align="right">Price / month</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>1 - 10</TableCell>
                    <TableCell align="right">$10</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>11 - 20</TableCell>
                    <TableCell align="right">$29</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>21 - 30</TableCell>
                    <TableCell align="right">$49</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>31 - 40</TableCell>
                    <TableCell align="right">$69</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>41 - 50</TableCell>
                    <TableCell align="right">$89</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>51 - 60</TableCell>
                    <TableCell align="right">$109</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>61 - 75</TableCell>
                    <TableCell align="right">$129</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>76 - 100</TableCell>
                    <TableCell align="right">$169</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>101 - 150</TableCell>
                    <TableCell align="right">$199</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>151 - 200</TableCell>
                    <TableCell align="right">$249</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>201 - 300</TableCell>
                    <TableCell align="right">$299</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>301 - 500</TableCell>
                    <TableCell align="right">$399</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>501+</TableCell>
                    <TableCell align="right">$499</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </div>
      </div>
    </Styled>
  );
};

export default PricingMonday;

const Styled = styled.div`
  display: flex;
  flex-direction: column;

  .bold {
    font-weight: bold;
  }

  .success-continue-btn {
    margin-top: 20px;
  }

  .pricing-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 2rem 0 1rem 0;
    gap: 2rem;

    .pricing-card {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      max-width: 700px;
      min-height: 200px;
      padding: 1rem;

      .login-button {
        margin: 2rem 0 0.5rem 0;
        width: 220px;
      }
    }
  }

  .questions-btn {
    margin-top: 2rem;
  }

  .top-gap {
    margin-top: 3rem;
  }

  .price-per-seat {
    margin-top: 1rem;
    margin-bottom: 3rem;
  }

  .table-container {
    max-width: 300px;
  }

  .emphasize-text {
    font-weight: bold;
  }

  .help-icon {
    margin-left: 0.3rem;
  }
`;
